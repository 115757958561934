.timelineRoot {
    margin: 0 auto;
    max-width: 700px;
    width: 100%;
    display: flex;
    flex-direction: column;
    --track-color: #003c77;
    --event-color: #186b15;
}

.timelineRoot .timelineItem {
    display: flex;
    flex-direction: row;
}

.timelineRoot .timelineItem .track {
    width: 80px;
    position: relative;
    display: flex;
    justify-content: center;
}
.timelineRoot .timelineItem .track::before {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    border-left: 4px solid var(--track-color);
    z-index: -999;
}
.timelineRoot .timelineItem:first-child .track::before {
    top: 30px;
}
.timelineRoot .timelineItem:last-child .track::before {
    bottom: auto;
    height: 30px;
}
.timelineRoot .timelineItem .track .dot {
    margin: 10px 0;
    width: 50px;
    height: 50px;
    border: 3px solid var(--track-color);
    box-shadow: inset 0 0 0 3px white;
    border-radius: 100%;
    background-color: var(--event-color);
    color: white;
    aspect-ratio: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.timelineRoot .timelineItem.break .track::before {
    border-left-style: dashed;
}

.timelineRoot .timelineItem .body {
    flex: 1;
    padding: 10px 20px 20px 5px;
}
.timelineRoot .timelineItem.break .body {
    padding: 0 20px 0 5px;
}

.timelineRoot .timelineItem .body .card {
    border-radius: 6px;
    position: relative;
    overflow: visible;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}
.timelineRoot .timelineItem .body .card::before {
    content: " ";
    position: absolute;
    top: 18px;
    left: -10px;
    border-right: 10px solid var(--event-color);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}
.timelineRoot .timelineItem .body .card .title {
    display: flex;
    background-color: var(--event-color);
    border-radius: 6px 6px 0 0;
    color: white;
    font-family: var(--heading-font);
    padding: 4px 12px 6px;
    font-size: 22pt;
    cursor: default;
    flex-wrap: wrap;
}

.timelineRoot .timelineItem .body .card .title .link {
    flex: 1;
    text-decoration: none;
    color: white;
}
.timelineRoot .timelineItem .body .card .title a.link[href]:hover {
    text-decoration: underline;
    cursor: pointer;
}

.timelineRoot .timelineItem .body .card .title .date {
    font-size: 12pt;
    display: flex;
    align-items: end;
    justify-content: right;
    padding-bottom: 3px;
}

.timelineRoot .timelineItem .body .card .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}

.timelineRoot .timelineItem .body .card .coverImage {
    width: 100%;
    background-color: #eee;
    background-size: cover;
    aspect-ratio: 2 / 1;
}
