:root {
    --heading-font: Oswald, sans-serif;
    --page-width: 1000px;
}

.titleHighlight {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
.titleHighlight::before,
.titleHighlight::after {
    content: " ";
    border-bottom: 2px solid #ccc;
    flex: 1;
}

.markdownHost img {
    max-width: 100%;
    max-height: 40vh;
    margin: 0 auto;
    text-align: center;
}
